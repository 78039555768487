<template>
	<el-dialog title="周报导出" :close-on-click-modal="false" :visible.sync="visible"
		width="800px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="时间" prop="plannedTime">
				<el-date-picker  class="selItemInput"
					  v-model="timeArray"
					  type="daterange"
					  range-separator="至"
					  start-placeholder="开始日期"
					  end-placeholder="结束日期"
								 value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "weekly-add-or-update",
		data() {
			return {
				visible: false,
				inline: false,
				timeArray:[],
				dataForm: {

				},
				dataRule: {},
			}
		},
		methods: {
			init() {
                this.visible = true;

			},
            // 表单提交
            dataFormSubmit() {
                this.$router.push({name:'weeklyExportInfo',params:{beginTime:this.timeArray[0],endTime:this.timeArray[1]}});
        //         this.$refs["dataForm"].validate(valid => {
        //             if (valid) {
        //                 this.$http({
        //                     url:  this.$store.state.httpUrl +"/business/fileinfo/saveWeek",
        //                     method: "post",
        //                     data: this.$http.adornData({
        //                         workNo: $common.getItem("workNo"),
								// subjectNo:$common.getItem("subjectNo"),
        //                         fileNo: this.dataForm.fileNo,
        //                         fileTitle:this.dataForm.fileTitle,
        //                         insertDate:this.dataForm.insertDate,
        //                     })
        //                 }).then(({ data }) => {
        //                     if (data && data.resultCode === 200) {
        //                         // 跳转页面

        //                     } else {
        //                         this.$message.error(data.msg);
        //                     }
        //                 });
        //             }
        //         });
            },
		}
	}
</script>
<style scoped>

</style>
