<template>
	<!-- 监理周报 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">监理周报</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan">
					<!-- <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-refresh" @click="resetFormData()"
					v-if="showSearch">刷新</el-button> -->
					<!--			<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-download" @click="exportHandle()" v-if="showDownload">周报导出</el-button>-->
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()"
						v-if="showAdd">创建</el-button>
					<div style="color: red;margin-top:7px;margin-bottom: 7px;" v-if="!showAdd">
						本项目您设置的不是当前类型周报，不能创建该类型的周报。
					</div>
					<!-- <el-button v-preventReClick class="btn" type="primary" size="small"
						@click="weeklyReportUpload()">上传</el-button> -->
				</el-col>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -3px; height: 590px;" body-style="height:550px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="530" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%; margin-bottom: 20px;"
								header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * limit + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="weeklyName" label="周报编号" min-width="200" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="viewPdf(scope.row.filePath, scope.row.filePathUri, scope.row.weeklyName)">{{
												scope.row.weeklyName }}</el-button>
									</template>
								</el-table-column>
								<el-table-column label="日期" min-width="180" align="center">
									<template slot-scope="scope">
										{{ scope.row.beginTime }} ~~ {{ scope.row.endTime }}
									</template>
								</el-table-column>
								<!-- <el-table-column prop="beginTime" label="开始时间" min-width="160" align="center">
								</el-table-column>
								<el-table-column prop="endTime" label="结束时间" min-width="160" align="center">
								</el-table-column> -->
								<el-table-column prop="insDate" label="制作日期" min-width="100" align="center">
								</el-table-column>
								<el-table-column label="确认状态" width="100" align="center">
									<template slot-scope="scope">
										<span v-if="scope.row.refirmFlg === '1'">已确认</span>
										<span v-else>未确认</span>
									</template>
								</el-table-column>
								<el-table-column prop="refirmMan" label="确认人" width="110" align="center">
								</el-table-column>
								<el-table-column prop="refirmTime" label="确认时间" width="110" align="center">
								</el-table-column>
								<!-- <el-table-column prop="weekKindName" label="形式 " min-width="80" align="center">
								</el-table-column> -->
								<!-- <el-table-column prop="examineStatus" label="审核状态" width="140" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.examineStatus === '1' && scope.row.weekKind === '0'" style="color: #e7b243;">已审核</span>
								<span v-if="scope.row.examineStatus !== '1' && scope.row.weekKind === '0'">待审核</span>
							</template>
						</el-table-column>
						<el-table-column label="审核时间 " width="140" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.examineStatus === '1'">{{ scope.row.examineTime }}</span>
							</template>
						</el-table-column> -->
								<el-table-column label="操作" min-width="200" fixed="right"
									v-if="showDelete || showEdit || showCheck || showRowDownload" align="left">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="editHandle(scope.row.recNo, scope.row.reportKind, scope.row.weeklyStatus)"
											v-if="showEdit && scope.row.refirmFlg != '1'">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="createHandle(scope.row.recNo)"
											v-if="scope.row.weeklyStatus === '1' && scope.row.refirmFlg !== '1'">生成</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="fileUploadHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg !== '1'">上传</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="refirmHandle(scope.row.recNo)"
											v-if="scope.row.showRefirm === '1'">确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="cancelRefirmHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg === '1'">解除确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="downloadHandle(scope.row.recNo)"
											v-if="showRowDownload">下载</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="zipDownLoadWeekly(scope.row)"
											v-if="showRowDownload">打包下载</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recNo)"
											v-if="showDelete && scope.row.refirmFlg != '1'">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="page" :page-sizes="[10, 20, 50, 100]"
							:page-size="limit" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<!-- <weekly-report-upload v-if="reportUploadVisible" ref="weeklyReportUpload"
			@refreshDataList="getDataList"></weekly-report-upload> -->
		<weekly-export v-if="exportVisible" ref="weeklyExport" @refreshDataList="getDataList"></weekly-export>
		<audit v-if="auditVisible" ref="audit" @refreshDataList="getDataList"></audit>
		<weekly-file-upload v-if="fileUploadVisible" ref="weeklyFileUpload"
			@refreshDataList="getDataList"></weekly-file-upload>
		<enclosure-add-or-update v-if="enclosureVisible" ref="enclosureAddOrUpdate"
			@refreshDataList="getDataList"></enclosure-add-or-update>
		<weekly-report-download v-if="downloadVisible" ref="weeklyReportDownload"
			@refreshDataList="getDataList"></weekly-report-download>
		<weekly-report-file-upload v-if="reportFileUploadVisible" ref="weeklyReportFileUpload"
			@refreshDataList="getDataList"></weekly-report-file-upload>
		<weekly-refirm v-if="refirmVisible" ref="weeklyRefirm" @refreshDataList="getDataList"></weekly-refirm>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>
<script>
import AddOrUpdate from './weekly-add-or-update'
import WeeklyExport from './weekly-export'
import EnclosureAddOrUpdate from './enclosure-list'
import $common from "@/utils/common.js"
import audit from './weekly-audit'
import WeeklyFileUpload from "./weekly-file-upload";
import WeeklyReportUpload from "./weekly-report-upload"
import WeeklyReportDownload from "./weekly-report-download.vue"
import WeeklyReportFileUpload from "./weekly-report-file-upload.vue"
import WeeklyRefirm from "./weekly_refirm"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	data() {
		return {
			viewPdfVisible: false,
			showSearch: false,
			showDownload: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			showCheck: false,
			action: '',
			showArchive: false,
			showRowDownload: false,
			pageSpan: 12,
			refirmVisible: false,
			addOrUpdateVisible: false,
			fileUploadVisible: false,
			exportVisible: false,
			auditVisible: false,
			enclosureVisible: false,
			reportUploadVisible: false,
			downloadVisible: false,
			reportFileUploadVisible: false,
			form: {},
			limit: 20,
			page: 1,
			totalCount: 0,
			dataList: [],
			tableSelVal: [],
			// 显示序号
			pageIndex2: 1,
		};
	},
	components: {
		WeeklyReportFileUpload,
		WeeklyReportDownload,
		// WeeklyReportUpload,
		WeeklyFileUpload,
		AddOrUpdate,
		audit,
		WeeklyExport,
		EnclosureAddOrUpdate,
		WeeklyRefirm,
		ViewPdf
	},
	mounted() {
		this.showSearch = $common.isAuth('weekly:search');
		this.showDownload = $common.isAuth('weekly:download');
		this.showAdd = $common.isAuth('weekly:add');
		this.showEdit = $common.isAuth('weekly:edit');
		this.showDelete = $common.isAuth('weekly:delete');
		this.showCheck = $common.isAuth('weekly:check');
		this.showArchive = $common.isAuth('weekly:archive');
		this.showRowDownload = $common.isAuth('weekly:download');
		if (!this.showSearch && !this.showDownload && !this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
		this.valisShowAddButton();
	},
	methods: {
		valisShowAddButton() {
			this.$http({
				url: this.$store.state.httpUrl + "/index/infoName/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					let weeklyReportTypeId = data.body.weeklyReportTypeId;
					if(weeklyReportTypeId != '' && weeklyReportTypeId != undefined && weeklyReportTypeId != 'WEEK01' && weeklyReportTypeId != 'WEEK03') {
						this.showAdd = false;
					}
				}
			});
		},
		uploadFileHandle(id, weeklyName) {
			this.fileUploadVisible = true
			this.$nextTick(() => {
				this.$refs.weeklyFileUpload.init(id, weeklyName)
			})
		},
		enclosureHandle(id) {
			this.enclosureVisible = true
			this.$nextTick(() => {
				this.$refs.enclosureAddOrUpdate.init(id)
			})
		},
		viewPdf(filePath, fileUri, filename) {
			if(filePath == null || filePath == undefined || filePath == '') {
				this.$message.error("没有可预览的文件！");
				return;
			} 
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		weeklyReportUpload() {
			this.reportUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.weeklyReportUpload.init()
			})
		},
		fileUploadHandle(id) {
			this.reportFileUploadVisible = true
			this.$nextTick(() => {
				this.$refs.weeklyReportFileUpload.init(id)
			})
		},
		refirmHandle(id) {
			this.refirmVisible = true
			this.$nextTick(() => {
				this.$refs.weeklyRefirm.init(id)
			})
		},
		cancelRefirmHandle(id) {
			this.$confirm(`确定对选中的数据进行解除确认操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/cancelWeeklyRefirm/" + id,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		downloadHandle(id) {
			this.downloadVisible = true;
			this.$nextTick(() => {
				this.$refs.weeklyReportDownload.init(id)
			})
		},
		// 打包下载
		zipDownLoadWeekly(item) {
			var fileName = "监理周报（" + item.weeklyName + "）.zip";
			this.$http({
				url: this.$store.state.httpUrl + "/business/weeklyreportinfo/zipDownLoadWeekly?recNo=" + item.recNo,
				method: "POST",
				data: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.global.downLoadFile(data.body, fileName);
						}
					})
				}
			});
		},
		//重置
		resetFormData() {
			this.pageIndex = 1;
			this.getDataList();
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/list",
					method: 'get',
					params: this.$http.adornParams({
						limit: this.limit,
						page: this.page,
						subjectNo: $common.getItem("subjectNo"),
						workNo: $common.getItem("workNo")
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalCount = data.body.totalCount;
						this.pageIndex2 = this.page
					} else {
						this.dataList = []
						this.totalCount = 0
						this.pageIndex2 = 1
					}
				})
			}
		},
		// 审核
		auditeHandle(id) {
			this.auditVisible = true
			this.$nextTick(() => {
				this.$refs.audit.init(id)
			})
		},
		cancelAuditeHandle(id) {
			this.$confirm(`确定对选中的数据进行撤销审核生成周报吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/restoreCheckWeekly?recNo=" + id,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		cancelHandle(id) {
			this.$confirm(`确定对选中的数据进行还原生成周报吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/restoreWeekly?recNo=" + id,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		createHandle(id) {
			this.$confirm(`确定对选中的数据进行生成周报吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/downloadWeek?recNo=" + id,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		//删除
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/delete/" + id,
					method: "get",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 190;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		archiveFile(id) {
			this.$confirm(`确定对选中的发文进行归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/archiveFile/" + id,
					method: "get",
					data: id,
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error("归档设置失败")
					}
				});
			});
		},
		removeArchiveFile(id) {
			this.$confirm(`确定对选中的发文进行取消归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/weeklyreportinfo/removeArchiveFile/" + id,
					method: "get",
					data: id,
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error("取消归档失败")
					}
				});
			});
		},
		// 每页数
		sizeChangeHandle(val) {
			this.limit = val
			this.page = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		addOrUpdateHandle(id) {
			// this.$router.push({name:'weeklyExportInfo',params:{recNo:id,editFlag:"1"}});
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id);
			})
		},
		editHandle(id, reportKind, weeklyStatus) {
			if (reportKind === '2') {
				this.$router.push({ name: 'weeklyDeviceExportInfo', query: { recNo: id } });
			} else {
				this.refreshAllDataList(id)
				// if (weeklyStatus === "0") {
				// 	this.refreshEnclosureFiles(id)
				// } else {
				// 	this.refreshAllDataList(id)
				// }
			}
		},
		exportHandle() {
			this.exportVisible = true
			this.$nextTick(() => {
				this.$refs.weeklyExport.init()
			})
		},
		refreshEnclosureFiles(id) {
			let _url = "/business/weeklyreportinfo/refreshEnclosureFiles/" + id;
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: []
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 跳转
					this.$router.push({ name: 'weeklyExportInfo', query: { recNo: id } });
				}
			});
		},
		refreshAllDataList(id) {
			let _url = "/business/weeklyreportinfo/refreshAllDataList/" + id;
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: []
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 跳转
					this.$router.push({ name: 'weeklyExportInfo', query: { recNo: id } });
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}
</style>
