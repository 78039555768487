<template>
	<el-dialog :title="!dataForm.recNo ? '创建周报' : '修改周报'" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" class="border-form">
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="周报编号" prop="weeklyName" class="item">
						<el-input v-model="dataForm.weeklyName" placeholder="请输入" style="width: 400px;"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="时间" prop="plannedTime" class="item">
						<el-date-picker v-model="timeArray" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 400px;">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "weekly-add-or-update",
	data() {
		return {
			visible: false,
			inline: false,
			action: '',
			timeArray: [],
			dataForm: {
				recNo: '',
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				weeklyName: '',
				beginTime: '',
				endTime: '',
				filePath: '',
				fileType: '',
				weekKind: ''
			},
			dataRule: {
				weeklyName: [
					{ required: true, message: " ", trigger: "change" }
				]
			},
		}
	},
	methods: {
		init(id) {
			this.timeArray = [];
			this.dataForm = {
				recNo: '',
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				weeklyName: '',
				beginTime: '',
				endTime: '',
				filePath: '',
				fileType: '',
			};
			this.visible = true;
			this.dataForm.recNo = id ? id : '';
			this.$nextTick(() => {
				if (this.dataForm.recNo) {
					this.$http({
						url: this.$store.state.httpUrl + `/business/weeklyreportinfo/info/${this.dataForm.recNo}`,
						method: 'get',
						params: {}
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataForm = data.body
							this.timeArray = [this.dataForm.beginTime, this.dataForm.endTime];
						}
					})
				}
			});
			this.$refs['dataForm'].resetFields();
		},
		delFile() {
			this.dataForm.filePath = "";
			this.dataForm.loadFileName = "";
			this.dataForm.filePathUri = "";
			this.dataForm.pdfFilePath = "";
			this.dataForm.pdfFilePathUri = "";
		},
		selectFile() {
			window.open(this.dataForm.filePathUri);
		},
		selectPdfFile(pdfUrl) {
			window.open(pdfUrl);
		},
		// 表单提交
		dataFormSubmit() {
			this.dataForm.beginTime = this.timeArray[0];
			this.dataForm.endTime = this.timeArray[1];
			this.dataForm.weekKind = "0";
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/weeklyreportinfo/save";
					if (this.dataForm.recNo) {
						_url = "/business/weeklyreportinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									// if (this.dataForm.recNo) {
										this.visible = false;
										this.$parent.getDataList();
									// } else {
									// 	if (data.body.projectType === '2') {
									// 		this.$router.push({ name: 'weeklyDeviceExportInfo', query: { recNo: data.body.recNo } });
									// 	} else {
									// 		this.$router.push({ name: 'weeklyExportInfo', query: { recNo: data.body.recNo } });
									// 	}
									// }
								}
							});
						}
					});
				}
			});
		},
	}
}
</script>
<style lang="scss" scoped="scoped">
.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	//   display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
