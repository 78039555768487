<template>
    <el-dialog :title="'下载文件'" :close-on-click-modal="false" :visible.sync="subVisible" width="700px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="周报编号" prop="weeklyName">
                        {{ dataForm.weeklyName }}
                    </el-form-item>
                </el-col>
        </el-row>
            <el-row style="margin-left: 40px;">
                <el-col :span="24" v-if="dataForm.fileName">
                    <el-col :span="18">
                        <el-button v-preventReClick type="text" size="small" style="color: #00428e;"
								@click="viewPdf(dataForm.filePath, dataForm.filePathUri, dataForm.fileName)">
                                {{dataForm.fileName }}
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button v-preventReClick type="primary" size="small" @click="downloadFile('0', dataForm.filePathUri, dataForm.fileName)"
                        style="margin-left: 20px; ">下载</el-button>
                    </el-col>
                </el-col>
            </el-row>
            <el-row v-for="(enclosureFile, index) in dataForm.enclosureFiles" style="margin-top: 10px;margin-left: 40px;">
                <el-col :span="24" v-if="dataForm.enclosureFiles">
                    <el-col :span="18">
                        <el-button v-preventReClick type="text" size="small" style="color: #00428e;"
								@click="viewPdf(enclosureFile.filePath, enclosureFile.filePathUri, enclosureFile.fileName)">
                                {{ enclosureFile.fileName }}
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                    <el-button v-preventReClick type="primary" size="small" style="margin-left: 20px;"
                        @click="downloadFile('1', enclosureFile.filePathUri, enclosureFile.fileName)">下载</el-button>
                    </el-col>
                </el-col>
            </el-row>
        </el-form>
        <el-divider></el-divider>
        <view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
    </el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
import ViewPdf from "../projectInfo/viewPdf.vue"
import { Message } from 'element-ui';
export default {
    name: "weekly-file-upload",
    data() {
        return {
            viewPdfVisible: false,
            addImg: addImg,
            id: "",
            subVisible: true,
            form: {},
            action: "",
            fileType: "",
            dataForm: {
                recNo: '',
                weeklyName: '',
                fileName: "",
                filePath: "",
                enclosureFiles: '',
            },
            dataRule: {
                weeklyName: [
                    { required: true, message: "周报名称不能为空！", trigger: "blur" }
                ],
            }
        };
    },
    components: {
        ViewPdf
    },
    activated() {

    },
    methods: {
        init(id) {
            this.dataForm = {
                recNo: '',
                weeklyName: '',
                filePath: '',
                filePathUri: '',
                fileName: '',
                enclosureFiles: [],
                weekKind: ''
            }
            this.dataForm.recNo = id
            this.getDetail();
            this.$nextTick(() => {
                this.subVisible = true;
            })
            this.$refs['dataForm'].resetFields();
        },
        viewReportFile() {
            window.open(this.dataForm.filePathUri);
        },
        viewEncolsureFile(index) {
            window.open(this.dataForm.enclosureFiles[index].filePathUri);
        },
        viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
                this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
        downloadFile(type, fileUri, filename) {
            if( this.dataForm.weekKind === '0' && type !== '0') {
                filename = this.getSuffix(fileUri, filename)
            }
            
            this.getBlob(fileUri, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            const rLoading = this.openLoading();
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                    rLoading.close();
                } else if(xhr.status === 404){
                    rLoading.close();
                    Message.error('文件不存在')
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },

        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/weeklyreportinfo/info/" + this.dataForm.recNo,
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.weeklyName = data.body.weeklyName;
                    this.dataForm.filePath = data.body.filePath;
                    this.dataForm.filePathUri = data.body.filePathUri;
                    this.dataForm.fileName = data.body.fileName;
                    // this.dataForm.enclosureFiles = data.body.enclosureFiles;
                    // 附件列表，重新设置附件名称
                    let enclosureFiles = []
                    if(data.body.enclosureFiles != undefined && data.body.enclosureFiles.length > 0) {
                        for(var i = 0; i < data.body.enclosureFiles.length; i++) {
                            data.body.enclosureFiles[i].fileName = '附件' + (i + 1 ) + "：" + data.body.enclosureFiles[i].fileName
                            enclosureFiles.push(data.body.enclosureFiles[i]);
                        }
                    }
                    this.dataForm.enclosureFiles = enclosureFiles;
                    this.dataForm.weekKind = data.body.weekKind;
                }
            });
        },
    }
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
    width: 1030px !important;
}

.addImgBox {
    width: 136px;
    height: 136px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
}

.el-divider {
    margin: 60px 0;
    background: 0 0;
    border-top: 1px solid #E6EBF5;
}

.tmp {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 358px;
    display: inline-block;
    color: #00428e;
    // margin-top: 13px;
}
</style>
