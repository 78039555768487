import { render, staticRenderFns } from "./weekly-file-upload.vue?vue&type=template&id=13600860&scoped=true&"
import script from "./weekly-file-upload.vue?vue&type=script&lang=js&"
export * from "./weekly-file-upload.vue?vue&type=script&lang=js&"
import style0 from "./weekly-file-upload.vue?vue&type=style&index=0&id=13600860&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13600860",
  null
  
)

export default component.exports