<template>
	<el-dialog
		:title="'上传周报'"
		:close-on-click-modal="false"
		:visible.sync="subVisible"
		width="800px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="24">
					<el-form-item label="周报编号" prop="fileName">
						{{dataForm.weeklyName}}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="周报文件">
						<div class="addFileBox">
						    <div v-if="dataForm.filePathUri">
								<el-button v-preventReClick type="success" size="small" @click="selectFile()">查看</el-button>
								<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
							</div>
						</div>
						<el-upload  v-if="!dataForm.filePathUri"
						  class="addFileBox"
						  ref="uploadScanFile"
						  :action="action"
						  :http-request="uploadScanFile">
						  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			  <el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="subVisible = false">取消</el-button>
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
			  </el-row>
		</el-form>
	</el-dialog>
</template>

<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
	  name: "weekly-file-upload",
	  data() {
	    return {
			addImg: addImg,
			id: "",
			subVisible: true,
			form:{},
			action: "",
			fileType:"",
			dataForm:{
				recNo: '',
				weeklyName: '',
				fileName:"",
				filePath:"",
				filePathUri:'',
			}
	    };
	  },
	  components: {

	  },
	  activated() {

	  },
	  methods: {
		init(id,weeklyName) {
			this.dataForm = {
				recNo: '',
				weeklyName: '',
				fileName:"",
				filePath:"",
				filePathUri:''
			}
			this.dataForm.recNo = id?id:0
			this.dataForm.weeklyName = weeklyName
			this.getDetail();
			this.$nextTick(() => {
				this.subVisible = true;
			})
			this.$refs['dataForm'].resetFields();

		},
		selectFile(){
			window.open(this.dataForm.filePathUri);
		},
		delFile(){
			this.dataForm.filePath = "";
			this.dataForm.loadFileName = "";
			this.dataForm.filePathUri = "";
		},
		  getDetail(){
			  this.$http({
				  url: this.$store.state.httpUrl + "/business/weeklyreportinfo/info/"+this.dataForm.recNo,
				  method: "get",
				  params: {},
			  }).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					  this.dataForm.weeklyName = data.body.weeklyName;
					  this.dataForm.filePath = data.body.endFilePath;
					  this.dataForm.filePathUri = data.body.endFilePathUrl;
				  }
			  });
		  },
		  uploadScanFile(param){
			  const formData = new FormData();
			  formData.append('file', param.file);
			  this.$refs.uploadScanFile.clearFiles();
			  this.$http.post(
					  this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/95/0",
					  formData,
					  {
						  headers: { 'Content-Type': 'multipart/form-data' }
					  },
			  ).then(res => {
				  res = res.data
				  if (res.resultCode === 200){
					  this.dataForm.filePath = res.body.fileSavePath;
					  this.dataForm.loadFileName = res.body.fileName;
					  this.dataForm.filePathUri = res.body.fileUri;
				  } else {
					  this.$message.info('文件上传失败')
				  }
			  })
		  },
		dataFormSubmit() {
		  this.$refs["dataForm"].validate(valid => {
		    if (valid) {
				let _url = "/business/weeklyreportinfo/update";
		      this.$http({
		        url: this.$store.state.httpUrl + _url,
		        method: "post",
		        data: this.$http.adornData({
					recNo: this.dataForm.recNo,
					endFilePath: this.dataForm.filePath
				})
		      }).then(({ data }) => {
		        if (data && data.resultCode === 200) {
		          this.$message({
		            message: "操作成功",
		            type: "success",
		            duration: 1500,
		            onClose: () => {
		              this.subVisible = false;
		              this.$emit("refreshDataList");
		            }
		          });
		        } else {
		          this.$message.error(data.msg);
		        }
		      });
		    }
		  });
		}
	  }
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg{
		width: 1030px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		position: relative;
	}
</style>
